// src/App.js
import React from "react";
import { ReviewImportProvider } from "./context/ReviewImportContext";
import ReviewImportPage from "./pages/ReviewImportPage";
import Navbar from "./components/Navbar";

const App = () => {
  return (
    <ReviewImportProvider>
      <Navbar />
      <div className="min-h-screen bg-gray-100">
        <ReviewImportPage />
      </div>
    </ReviewImportProvider>
  );
};

export default App;
