import React, { useState, useContext } from "react";
import { ReviewImportContext } from "../context/ReviewImportContext";
import {
  LockClosedIcon,
  XMarkIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";

const LoginComponent = () => {
  const { handleLogin, setShowLogin } = useContext(ReviewImportContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const submitCredentials = () => {
    handleLogin(username, password);
  };

  const closeModal = () => {
    setShowLogin(false);
  };

  const onBackdropClick = (e) => {
    if (e.currentTarget === e.target) {
      closeModal();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50	"
      onClick={onBackdropClick}
    >
      <div className="relative bg-white p-6 rounded-md shadow-lg max-w-sm w-full">
        <div className="flex justify-end">
          <button
            onClick={closeModal}
            className="text-gray-600 hover:text-gray-800"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        <div className="text-center">
          <UserCircleIcon className="mx-auto mb-3 h-12 w-12 text-gray-500" />
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Product Integration
          </h3>
          <p className="text-sm text-gray-400 mt-2">
            To import reviews, you need to login as a Product Integration
            member.
          </p>
          <div className="mt-4">
            <div className="flex items-center border border-gray-300 rounded-md p-2">
              <UserCircleIcon className="w-5 h-5 text-gray-500" />
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
                className="flex-1 ml-2 bg-transparent focus:outline-none"
              />
            </div>
            <div className="flex items-center border border-gray-300 rounded-md p-2 mt-4">
              <LockClosedIcon className="w-5 h-5 text-gray-500" />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="flex-1 ml-2 bg-transparent focus:outline-none"
              />
            </div>
            <button
              onClick={submitCredentials}
              className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 w-full"
            >
              Log In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
