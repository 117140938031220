const Tooltip = ({ message, children }) => {
  return (
    <div className="group cursor-pointer relative inline-block">
      {children}
      <div className="w-[200px]  absolute invisible group-hover:visible opacity-0 group-hover:opacity-100 bg-black text-white text-sm rounded py-1 px-2 bottom-full left-1/2 transform -translate-x-1/2 -mb-2 z-10 transition-opacity duration-300 ease-in-out">
        {message}
      </div>
    </div>
  );
};

export default Tooltip;
