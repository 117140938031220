import React, { useContext, useState } from "react";
import { ReviewImportContext } from "../context/ReviewImportContext";
import {
  InformationCircleIcon,
  ClipboardIcon,
} from "@heroicons/react/24/outline";
import { format } from "date-fns";

const LogComponent = () => {
  const { logData } = useContext(ReviewImportContext);
  const [openLogIndex, setOpenLogIndex] = useState(null);

  const toggleLogDetails = (index) => {
    setOpenLogIndex(openLogIndex === index ? null : index);
  };

  const copyToClipboard = (log) => {
    const logText = `${format(new Date(log.timestamp), "PPPpp")} - ${
      log.message
    }\nDetails:\n${log.details}`;
    navigator.clipboard.writeText(logText).then(() => {
      // Handle successful copying, e.g., show a toast notification
    });
  };

  const reversedLogData = [...logData].reverse();

  return (
    <div className="my-4 max-h-96 overflow-y-auto border border-gray-300 rounded-lg p-4 bg-white shadow">
      <h3 className="text-lg font-semibold mb-2">Application Logs</h3>
      {reversedLogData.length === 0 ? (
        <p className="text-gray-700">No logs available.</p>
      ) : (
        reversedLogData.map((log, index) => (
          <div key={index} className="mb-2">
            <div className="flex justify-between items-center">
              <button
                onClick={() => toggleLogDetails(index)}
                className="flex items-center text-sm text-gray-600 font-semibold hover:bg-gray-100 rounded-lg p-2 flex-grow"
              >
                <InformationCircleIcon className="h-5 w-5 text-blue-500 mr-2" />
                {format(new Date(log.timestamp), "PPPpp")} - {log.message}
              </button>
              <button
                onClick={() => copyToClipboard(log)}
                className="ml-2 p-2 hover:bg-gray-100 rounded-lg"
              >
                <ClipboardIcon className="h-5 w-5 text-gray-500" />
              </button>
            </div>
            {openLogIndex === index && (
              <div className="text-sm text-gray-500 mt-2 ml-7">
                Details:
                <pre className="bg-gray-200 p-3 rounded">{log.details}</pre>
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default LogComponent;
