// src/components/DragDropUploadComponent.js
import React, { useCallback, useState, useRef } from "react";
import { DocumentTextIcon } from "@heroicons/react/24/outline"; // Ensure you have @heroicons/react installed

const DragDropUploadComponent = ({ onFileSelect }) => {
  const [isDragActive, setIsDragActive] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const fileInputRef = useRef(null);

  const onButtonClick = () => {
    fileInputRef.current.click();
  };

  const onChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFileName(file.name);
      onFileSelect(file);
    }
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    setIsDragActive(true);
  }, []);

  const onDragLeave = useCallback((event) => {
    event.preventDefault();
    setIsDragActive(false);
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      setIsDragActive(false);
      const file = event.dataTransfer.files[0];
      if (file) {
        setSelectedFileName(file.name);
        onFileSelect(file);
      }
    },
    [onFileSelect]
  );

  return (
    <div
      className={`w-full px-4 py-20 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer ${
        isDragActive ? "bg-blue-100 border-blue-500" : "bg-gray-100"
      }`}
      onClick={onButtonClick}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        onChange={onChange}
        accept=".csv, text/csv"
      />
      {selectedFileName ? (
        <div className="flex items-center justify-center space-x-2">
          <DocumentTextIcon className="w-6 h-6 text-gray-400" />
          <span className="font-medium text-gray-700">{selectedFileName}</span>
        </div>
      ) : (
        <p className="text-center text-gray-700">
          Drag 'n' drop a CSV file here, or click to select a file
        </p>
      )}
    </div>
  );
};

export default DragDropUploadComponent;
