import React, { useState, useContext } from "react";
import CSVFileValidator from "csv-file-validator";
import { ReviewImportContext } from "../context/ReviewImportContext";
import DragDropUploadComponent from "./DragDropUploadComponent";
import DownloadExampleCSV from "./DownloadExampleCSV";

import { FolderArrowDownIcon } from "@heroicons/react/24/outline";

const UploadCSVComponent = () => {
  const {
    handleFileUpload,
    addLog,
    handleSetCSV,
    handleClearTable,
    handleSetValidationErrors,
  } = useContext(ReviewImportContext);
  const [selectedFile, setSelectedFile] = useState(null);

  const requiredHeaders = [
    "channel.id",
    "review.title", //TODO: this is not mandatory, but highlight it (if empty - statement)
    "review.comment",
    "review.locale",
    "review.rating",
    "review.submittedAt", //TODO: add review.userDefineId below
    "transaction.reference",
    "transaction.date",
    "review.reply.comment", //TODO: add customer.email, customer.firstName, customer.lastName, customer.userDefinedId, customer.address, tracking.source mandatory column, non-critical error
    "review.reply.repliedOn",
  ];
  const mandatoryFields = [
    "channel.id",
    "review.locale",
    "review.rating",
    "review.submittedAt",
    "transaction.reference",
  ];

  const isValidDate = (dateStr) => {
    // Check for ISO format (yyyy-mm-dd)
    if (!isNaN(new Date(dateStr).getTime())) {
      return true;
    }

    // Check for dd.mm.yyyy, dd-mm-yyyy, and dd/mm/yyyy
    const regex =
      /^(0?[1-9]|[12][0-9]|3[01])[-./](0?[1-9]|1[012])[-./](19|20)\d\d$/;
    if (regex.test(dateStr)) {
      const parts = dateStr.split(/[-./]/);
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // JavaScript months are 0-based
      const year = parseInt(parts[2], 10);

      const date = new Date(year, month, day);
      return (
        date.getFullYear() === year &&
        date.getMonth() === month &&
        date.getDate() === day
      );
    }

    return false;
  };

  console.log(
    "Mandatory fields [includes required headers?]: ",
    mandatoryFields.includes(requiredHeaders[7])
  );

  const CSVConfig = {
    headers: requiredHeaders.map((header) => ({
      name: header,
      inputName: header,
      required: mandatoryFields.includes(header),
      optional: !mandatoryFields.includes(header),
      validate: (field, row, column, value) => {
        if (
          mandatoryFields.includes(header) &&
          (field === undefined || field === null || field === "")
        ) {
          return false; // Mandatory fields must not be undefined, null, or empty
        }
        if (
          requiredHeaders.includes(header) &&
          !mandatoryFields.includes(header)
        ) {
          return field !== undefined && field !== null;
        }

        // Specific field validations
        switch (header) {
          case "channel.id":
            return field.startsWith("chl-");
          case "review.locale":
            return /^[a-z]{2}_[A-Z]{2}$/.test(field);
          case "review.rating":
            return (
              !isNaN(field) &&
              field >= 1 &&
              field <= 5 &&
              /^(\d+(\.\d+)?|\.\d+)$/.test(field)
            );
          case "review.submittedAt":
          case "review.reply.repliedOn":
          case "transaction.date":
            return isValidDate(field);
          default:
            return true; // No specific validation for other fields
        }
      },
    })),
  };

  const handleFileSelected = (file) => {
    setSelectedFile(file);
  };

  const onFileUpload = async () => {
    console.log("Selected file: ", selectedFile);
    if (selectedFile !== null) {
      handleClearTable();

      try {
        const result = await CSVFileValidator(selectedFile, CSVConfig);

        if (result.inValidData.length === 0) {
          addLog({
            type: "Success",
            message: `CSV file [${selectedFile.name}] validated successfully with no errors.`,
            details: [
              {
                message: "All data is correct. Nothing to do :)",
              },
            ],
          });
          handleFileUpload(result.data);
          handleSetCSV(result.data);
        } else {
          addLog({
            type: "Error",
            message: `CSV file [${selectedFile.name}] validation failed. Errors: ${result.inValidData.length}`,
            details: result.inValidData,
          });
          handleSetCSV(result.data);
          handleSetValidationErrors(result.inValidData);
        }
      } catch (error) {
        console.error("Error validating CSV:", error);
        addLog({
          type: "Error",
          message: `CSV file [${selectedFile.name}] validation encountered an error.`,
          details: error.toString(),
        });
      }
    } else {
      addLog({ type: "Warning", message: "No CSV file selected for upload." });
      // Optionally handle the case when no file is selected
    }
  };

  return (
    <div className="flex flex-col items-center justify-between p-6 my-4 space-y-4 bg-white rounded-lg shadow-md">
      <DragDropUploadComponent onFileSelect={handleFileSelected} />
      <div className="flex w-[350px] justify-around">
        <button
          className="px-6 py-2 text-white bg-blue-600 rounded hover:bg-blue-700 focus:outline-none focus:ring"
          onClick={onFileUpload}
        >
          Upload
        </button>
        <DownloadExampleCSV />
      </div>
      {/* Display errors if any */}
    </div>
  );
};

export default UploadCSVComponent;
