import React, { createContext, useState } from "react";

// Create the context
export const ReviewImportContext = createContext();

// Create the provider component
export const ReviewImportProvider = ({ children }) => {
  const [csvData, setCsvData] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [logData, setLogData] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  // Function to handle file upload
  const handleFileUpload = (data) => {
    setValidationErrors([]);
    // TODO: Perform backend call for further validation if necessary
  };

  const handleSetCSV = (data) => {
    console.log("CSV is set as Context [ReviewImportContext.js]");

    setCsvData(data);
  };

  const handleClearTable = () => setCsvData("");

  // Function to handle backend validation response
  const handleValidationResponse = (errors) => {
    setValidationErrors(errors);
  };

  /**
   * Adds a new log entry to the application's log data.
   *
   * This function takes an error object and formats it into a structured log entry.
   * Each log entry includes a timestamp, a summary message, and detailed error messages.
   * The log entry is then added to the state array of log data.
   *
   * @param {Object} errorObject - The error object containing error details.
   * @param {string} errorObject.type - The type of the error (e.g., 'ValidationError').
   * @param {string} errorObject.message - A general descriptive message about the error.
   * @param {Array<Object>} errorObject.details - An array of objects, each describing a specific error detail.
   * @param {number} [errorObject.details[].rowIndex] - The row index related to the error (if applicable).
   * @param {number} [errorObject.details[].columnIndex] - The column index related to the error (if applicable).
   * @param {string} errorObject.details[].errorMessage - The detailed error message for the specific error.
   */
  const addLog = (errorObject) => {
    const errorType = errorObject.type || "Unknown Error Type";
    const errorMessage = errorObject.message || "An unknown error occurred";
    const errorSummary = `'${errorType}' occurred: ${errorMessage}`;

    const detailedErrors =
      errorObject.details &&
      Array.isArray(errorObject.details) &&
      errorObject.details.length > 0
        ? errorObject.details
            .map((e) => {
              const detailRow =
                e.rowIndex !== undefined ? `Row: ${e.rowIndex}, ` : "";
              const detailColumn =
                e.columnIndex !== undefined ? `Column: ${e.columnIndex}, ` : "";
              const detailMessage = e.message || "Unknown error detail";

              return `${detailRow}${detailColumn}${detailMessage}`;
            })
            .join("\n")
        : "No detailed information available";

    const newLogEntry = {
      timestamp: new Date().toISOString(),
      message: errorSummary,
      details: detailedErrors,
    };

    setLogData((prevLogData) => [...prevLogData, newLogEntry]);
  };

  const handleLogin = (username, password) => {
    // Here you would typically make an API call to the server to authenticate the user.
    // If successful, you might save an auth token and set some state indicating the user is logged in.

    // addLog("User logged in.");
    //TODO: repair logging

    setShowLogin(false);
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    // Perform any cleanup or state resetting here
    setIsAuthenticated(false);
    setShowLogin(false); // Optionally hide the LoginComponent if it's showing
    // Clear any stored tokens or user data
    // For example, if you're using localStorage for tokens:
    localStorage.removeItem("authToken");
    // addLog("User logged out.");
    //TODO: repair logging
  };

  const handleSetValidationErrors = (error) => {
    setValidationErrors(error);
  };

  // The value that will be supplied to any descendants of this provider
  const contextValue = {
    csvData,
    validationErrors,
    logData,
    isAuthenticated,
    showLogin,
    handleFileUpload,
    handleClearTable,
    handleSetCSV,
    handleValidationResponse,
    addLog,
    handleLogin,
    handleLogout,
    setShowLogin,
    handleSetValidationErrors,
  };

  return (
    <ReviewImportContext.Provider value={contextValue}>
      {children}
    </ReviewImportContext.Provider>
  );
};
