import React from "react";
import { FolderArrowDownIcon } from "@heroicons/react/24/outline";

const DownloadExampleCSV = () => {
  const handleDownload = async () => {
    try {
      const response = await fetch(
        "https://importer.productintegration.de/example.csv"
      );

      const data = await response.blob();

      const downloadUrl = window.URL.createObjectURL(data);

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "example.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <button
      onClick={handleDownload}
      className="inline-flex items-center px-6 py-2 text-white bg-green-600 rounded hover:bg-green-700 focus:outline-none focus:ring"
    >
      Download example CSV
    </button>
  );
};

export default DownloadExampleCSV;
