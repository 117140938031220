import React, { useContext } from "react";
import { ReviewImportContext } from "../context/ReviewImportContext";
import Tooltip from "./Tooltip";

const requiredHeaders = [
  "channel.id",
  "review.title",
  "review.comment",
  "review.locale",
  "review.rating",
  "review.submittedAt",
  "transaction.reference",
  "transaction.date",
  "review.reply.comment",
  "review.reply.repliedOn",
];

const mandatoryFields = [
  "channel.id",
  "review.locale",
  "review.rating",
  "review.submittedAt",
  "transaction.reference",
  // Add other mandatory fields as needed
];

const isTextTruncated = (text) => {
  const MAX_LENGTH = 100; // Adjust as needed
  return text.length > MAX_LENGTH;
};

const CSVTableComponent = () => {
  const { csvData, validationErrors } = useContext(ReviewImportContext);
  console.log("Validation errors: ", validationErrors);

  const getContentError = (header, rowIndex) => {
    return validationErrors.find(
      (e) =>
        e.columnIndex === requiredHeaders.indexOf(header) + 1 &&
        e.rowIndex === rowIndex + 2
    );
  };

  const getRowError = (rowIndex) => {
    return validationErrors.find(
      (e) => e.rowIndex === rowIndex + 2 && !e.hasOwnProperty("columnIndex")
    );
  };

  const hasHeaderError = (header) => {
    return validationErrors.some(
      (e) =>
        e.message.toLowerCase().includes(header.toLowerCase()) &&
        "rowIndex" in e &&
        e.rowIndex === 1
    );
  };

  return (
    <>
      {csvData && csvData.length > 0 && (
        <div className="my-4 max-h-[300%] overflow-y-auto border border-gray-300 rounded-lg p-4 bg-white shadow">
          <h3 className="text-lg font-semibold mb-2">CSV data preview</h3>

          <div className="overflow-x-auto rounded-lg">
            <table className="min-w-full border my-4 border-gray-200">
              <thead>
                <tr className="bg-gray-100">
                  {requiredHeaders.map((header, index) => (
                    <th
                      key={index}
                      className={`px-4 py-2 border border-gray-300 ${
                        hasHeaderError(header) ? "bg-red-100" : ""
                      }`}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {csvData.map((row, rowIndex) => {
                  const rowError = getRowError(rowIndex);

                  return (
                    <tr
                      key={rowIndex}
                      className={`${
                        rowError
                          ? "bg-red-100"
                          : rowIndex % 2 === 0
                          ? "bg-white"
                          : "bg-gray-50"
                      }`}
                    >
                      {requiredHeaders.map((header, headerIndex) => {
                        const value = row[header] || "(N/A)";
                        const contentError = getContentError(header, rowIndex);
                        let cellClass = "px-4 py-2 border border-gray-300";
                        let tooltipMessage = rowError
                          ? rowError.message
                          : contentError
                          ? contentError.message
                          : value;

                        const shouldShowTooltip =
                          rowError || contentError || isTextTruncated(value);
                        const cellBackground = contentError
                          ? "bg-red-100"
                          : rowError
                          ? "bg-red-100"
                          : "";

                        const cellContent = shouldShowTooltip ? (
                          <Tooltip message={tooltipMessage}>
                            <div className="truncate max-w-md overflow-hidden whitespace-nowrap">
                              {value}
                            </div>
                          </Tooltip>
                        ) : (
                          <div className="truncate max-w-md overflow-hidden whitespace-nowrap">
                            {value}
                          </div>
                        );

                        return (
                          <td
                            key={headerIndex}
                            className={`${cellClass} ${cellBackground}`}
                          >
                            {cellContent}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default CSVTableComponent;
