// src/pages/ReviewImportPage.js
import React, { useContext } from "react";
import packageJson from "../../package.json";

import { ReviewImportContext } from "../context/ReviewImportContext";

import UploadCSVComponent from "../components/UploadCSVComponent";
import CSVTableComponent from "../components/CSVTableComponent";
import LogComponent from "../components/LogComponent";
import LoginComponent from "../components/LoginComponent";
import TSXReviewImportComponent from "../components/TSXReviewImportComponent";

const ReviewImportPage = () => {
  const { showLogin, isAuthenticated } = useContext(ReviewImportContext);
  const { version, buildDate } = packageJson;
  return (
    <div className="p-6">
      <div className="fixed bottom-0 left-0 p-1 bg-red-500 text-white text-xs">
        v.{version} build: {buildDate}
      </div>

      {showLogin && <LoginComponent />}
      {isAuthenticated && <TSXReviewImportComponent />}
      <UploadCSVComponent />
      <LogComponent />
      <CSVTableComponent />
      {/* Additional TSX user functionalities will go here, gated by authentication */}
    </div>
  );
};

export default ReviewImportPage;
